import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Label} from 'reactstrap'
import moment from 'moment' 
import LifeSherpaLoading from '../LifeSherpaLoading';
import DialogTitle from "@mui/material/DialogTitle";
import Linkify from './Linkify';
import RctPDFViewer from "../../components/RctPDFViewer"
import { Capacitor } from '@capacitor/core';
import { isMobile, openURL, shareFileFromUrl, getLocalTempPathFile } from '../../helpers/helpers';
import IconButton  from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import CircularProgress from '@mui/material/CircularProgress';
import { FileOpener } from '@capacitor-community/file-opener';

class PostView extends Component {
    state = { 
        iframeLoading: true,
        windowWidth: window.innerWidth,
        showSpinner: false,
        pdfLoading: false
    }

    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    handleNativeBack = () => {
        console.log("native back clicked for actiivty filter popup ........");
        this.props.handleClose()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    async handleShare(post) {
        shareFileFromUrl(post, this.setLoading.bind(this));
    }

    setLoading(showSpinner) {
        this.setState({showSpinner})
    }

    setPDFLoading(pdfLoading) {
        this.setState({pdfLoading})
    }

    async handlePDFViewer(post) {
        const url = post.fileURL
        if (Capacitor.getPlatform() === 'android') {
            try {
                const fileOpenerOptions = await getLocalTempPathFile(post, this.setPDFLoading.bind(this))
                await FileOpener.open(fileOpenerOptions);
            } catch (error) {
                console.log("Sorry, we are mnot able to open this file right now, please connecti0ns", error)
            }
            
        } else if (Capacitor.getPlatform() === 'ios') {
            openURL(url)
        }
    }

    render() { 
        let {open, post } = this.props;
        const { iframeLoading, windowWidth, showSpinner, pdfLoading} = this.state;
        return ( 
                <Dialog
                    fullScreen={true}
                    open={open}
                    // disableBackdropClick={true}
                    onClose={()=>this.props.handleClose()}
                    maxWidth="xl"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} bullietin-post-viewer`}
                    id='bulletin-post-viewer-popup'
                    >
                    <DialogTitle>
                        <div className="d-flex justify-content-between align-items-center py-3 px-3 bg-lifesherpa">
                        <Label data-testid="show-img-dialog-done-button" className="listItemTitle mb-0 text-white cps" onClick={()=>this.props.handleClose()}>Done</Label>   
                        </div>
                    </DialogTitle>
                    <DialogContent className="p-4">
                        <div className='row'>
                            <div data-testid="show-resource-imgage-dialog" className={post.fileURL && !isMobile() ? `col-sm-12 col-md-8 clo-xl-9 col-lg-9` : "col-12" }>
                                {/* <img className="ing-fluid w-100" src={post.thumbnailURL} alt="" /> */}
                                {isMobile() &&
                                    <div className='w-100 text-right mb-2'>
                                        <IconButton title="Share Post" disabled={showSpinner} onClick={() => this.handleShare(post)}>
                                            <ShareIcon color="primary" />
                                            {showSpinner &&
                                                <CircularProgress
                                                    size={40}
                                                    sx={{
                                                    color: "primary",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-20px',
                                                    marginLeft: '-20px',
                                                    }}
                                                />
                                            }
                                        </IconButton> 
                                    </div>
                                }
                                {post.contentType && post.contentType.includes("image") ?
                                    <div className='image-container'>
                                        <img className="ing-fluid w-100" src={post.fileURL} alt="" />
                                    </div>
                                    :post.contentType && post.contentType.includes("video") ?
                                        <video width="100%" controls autoPlay loop muted>
                                            <source src={post.fileURL} type="video/mp4"/>
                                        </video>
                                    : post.fileURL && isMobile() /*windowWidth < 700*/ ?
                                        <>
                                            <LifeSherpaLoading loading={pdfLoading}/>
                                            <RctPDFViewer openPDFViewer={this.handlePDFViewer.bind(this)} post={post} fileUrl={post.fileURL} fullPages={isMobile()} initPageScale={isMobile() ? 0.51 : 0.72} />
                                        </>
                                    : post.fileURL ?
                                        <>
                                            <LifeSherpaLoading loading={iframeLoading}/>
                                            <iframe width="100%" src={post.fileURL} onError={() => this.setState({iframeLoading: false})} onLoad={() => this.setState({iframeLoading: false})} loading={<LifeSherpaLoading/>} />
                                        </>
                                    : <></>
                                }
                            </div>
                            <div className={post.fileURL && !isMobile() ? 'col-sm-12 col-md-4 clo-xl-3 col-lg-3' : "col-12"}>
                                <div>
                                    <div className="font-lg text-secondary">{post.title}</div>
                                    <div className="text-muted font-md mb-3">{moment(post.createdOn).format('MMM DD, YYYY')}</div>
                                    {post.description ? <div className="font-md text-secondary mb-3"> <Linkify text={post.description}/> </div> : ""}
                                    
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>    
            
        );
    }
}
const mapStateToProps = ({settings}) => {
    const { screenThemeClass, popupThemeClass } = settings; 
    return { screenThemeClass, popupThemeClass };
}

export default connect(mapStateToProps,{})(PostView);
