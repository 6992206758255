/**
 * Clients List Actions
 */

 import {axiosClient} from '../sherpaApi'
 import { NotificationManager } from 'react-notifications';
 
 import {
     GET_BUY_REWARDS,
     GET_PENDING_REWARDS,
     GET_RECIVED_REWARDS,
     CREDIT_AFTER_BUY_REWARD,
     BUY_NEW_REWARD,
     BUY_NEW_REWARD_SUCCESS,
     GET_REWARD_DATA_LOADING,
     HIDE_REWARD_DATA_LOADING,
     CONFIRM_REWARDS,
     CONFIRM_REARDS_SUCCESS,
     CLEAR_REWARDS_DATA,
     GET_PENDING_REWARDS_SUCCESS
 } from './types';

 /**
  * Redux Action To Emit Boxed Layout
  * @param {*boolean} isBoxLayout  */ 
 export const confirmRewards = (shoppingId, confirmerId) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId");
    let admin_uid = localStorage.getItem('auth_uid')
    let uid = localStorage.getItem('uid')
    let idToken = localStorage.getItem('idToken')
    const data  = {shoppingId, confirmerId: admin_uid}
    console.log('shoppingId', shoppingId)
    dispatch({type:CONFIRM_REWARDS})
    let url = `/users/${uid}/shopping/confirm-reward?organization=${orgId}`   ///users/{userId}/shopping/confirm-reward
    axiosClient.post(url, data, {headers: {
        'Authorization': `Bearer ${idToken}`
     }
    }).then((response) => {
        dispatch({type:CONFIRM_REARDS_SUCCESS})
        dispatch(getPendingRewards())
        dispatch(getreceivedRewards())
        dispatch(getRewards())
    }).catch((error) => {
        console.log("error occured ", error);
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message)
        dispatch({type:HIDE_REWARD_DATA_LOADING})
     })
 }
 
 export const getRewards= (initLoad) => (dispatch) => {
     let uid = localStorage.getItem('uid')
     let idToken = localStorage.getItem('idToken')
     const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId");
     let url = `/users/${uid}/rewards?organization=${orgId}` //users​/${memberUerId}​/rewards`
     dispatch({type:GET_REWARD_DATA_LOADING, payload: {initLoad}});
    axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("userRewardss",response.data)
        dispatch({type:GET_BUY_REWARDS,payload:response.data})
    }).catch((error) => {
        console.log("error occured ", error);
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message)
        dispatch({type:HIDE_REWARD_DATA_LOADING})
     })   
 }
 export const buyRewardByRewardId = (rewardId,credit) => (dispatch) => {
    let uid = localStorage.getItem('uid')
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    dispatch({type:BUY_NEW_REWARD})
    let url = `/users/${uid}/shopping/buy-reward/${rewardId}`   //users/{userId}/shopping/buy-reward/{rewardId}
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
     }
    }).then((response) => {
        dispatch({type:BUY_NEW_REWARD_SUCCESS})
        if(response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch(getPendingRewards())
        dispatch(getreceivedRewards())
        dispatch(getRewards())
        dispatch({type:CREDIT_AFTER_BUY_REWARD,payload:credit}) 
    }).catch((error) => {
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message);
        console.log("error occured ", error);
        dispatch({type:HIDE_REWARD_DATA_LOADING});
     })
 }
 
 export const getPendingRewards= (initLoad) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId");
    let uid = localStorage.getItem('uid')
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/shopping?type=pending&organization=${orgId}` ///users/{userId}/shopping
    dispatch({type:GET_REWARD_DATA_LOADING, payload: {initLoad}});
    dispatch({type: GET_PENDING_REWARDS})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:GET_PENDING_REWARDS_SUCCESS,payload:response.data})
    }).catch((error) => {
        console.log("error occured ", error);
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message)
        dispatch({type:HIDE_REWARD_DATA_LOADING})
     })    
}
export const getreceivedRewards= (initLoad) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId");
    let uid = localStorage.getItem('uid')
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/shopping?type=received&organization=${orgId}` ///users/{userId}/shopping
    dispatch({type:GET_REWARD_DATA_LOADING, payload: {initLoad}});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:GET_RECIVED_REWARDS,payload:response.data})
    }).catch((error) => {
        console.log("error occured ", error);
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message)
        dispatch({type:HIDE_REWARD_DATA_LOADING})
     })  
}

 export const addtoFavourite=(rewardId)=>(dispatch)=>{
    let uid = localStorage.getItem('uid')
    const orgId = localStorage.getItem("selectedOrgId")
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${uid}/rewards/${rewardId}/favourite`   //users/{userId}/rewards/{rewardId}/favourite
    dispatch({type:GET_REWARD_DATA_LOADING});
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
     }
    }).then((response) => {
        if(response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch(getPendingRewards())
        dispatch(getreceivedRewards())
        dispatch(getRewards())
    }).catch((error) => {
        console.log("error occured ", error);
        const message = error.response ? error.response.data.message : error.message
        NotificationManager.error(message)
        dispatch({type:HIDE_REWARD_DATA_LOADING})
     })  
 }

 export const clearRewardsData=() => (dispatch) => {
     dispatch({type: CLEAR_REWARDS_DATA})
 }
 
 
 
 
 
 