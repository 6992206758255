import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Video from "twilio-video";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import {isMobile} from '../../helpers/helpers';

class VideoDevices extends Component {
    state = {
        devices: [],
        anchorEl: null,
        cameraMode: 'Face Mode',
    }

    componentDidMount() {
        const { mediaAvailable } = this.props;
        console.log("[VideoDevices] mediaAvailable : ", mediaAvailable);
        this.getVideoDevices();
        document.addEventListener('nativeBackClick', this.handleCloseMenu);
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleCloseMenu);
    }

    getVideoDevices() {
        let self = this;
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
            console.log("[VideoDevices] mediaDevices : ", mediaDevices);
            const devices = [];
            mediaDevices.forEach(mediaDevice => {
                if (mediaDevice.kind === 'videoinput') {
                    devices.push(mediaDevice);
                    self.setState({ devices });
                    console.log("[VideoDevices] video devices : ", devices);
                }
            });
        }).catch((err) => {
            console.log("[VideoDevices] getVideoDevices failed : ", err);
        });
    }

    async updateVideoDevice(deviceId) {
        const { localParticipant, localVideoRef } = this.props;
        console.log("[VideoDevices] selected deviceId : ", deviceId, localVideoRef);
        this.handleCloseMenu();
        if (deviceId) {
            await this.stopStreamedVideo(document.getElementById(localParticipant.identity+"-"+'localParticipant'));
            await localParticipant.videoTracks.forEach(async publication => {
                if(publication.track !== null) {
                    publication.track.off('enabled', () => {});
                    publication.track.off('disabled', () => {});
                    await publication.track.stop();
                    console.log('track stopped twilio ');
                    publication.track.detach(localVideoRef.current);
                    await publication.unpublish();
                }
               
            });
            let  constraint = {deviceId: deviceId};
            if(isMobile()) {
                constraint = deviceId === 'Rear Mode' ? {facingMode: { exact: "environment" }}: { facingMode: "user" };
            }
            console.log("[VideoDevices] video constraint ", constraint);
            navigator.mediaDevices.getUserMedia({video: constraint, audio: false}).then(async (videoStream) =>  {
                let videoTracks = videoStream.getVideoTracks();
                console.log("[VideoDevices] videoTracks : ", videoTracks);
                let videoTrack = Video.LocalVideoTrack(videoTracks[0], {name: videoTracks[0].label});
                let publication = await localParticipant.publishTrack(videoTrack);
                videoTrack.attach(localVideoRef.current);
                console.log("[VideoDevices] added track publication :", publication);
            }).catch((err) => {
                console.error("[VideoDevices] getUserMedia failed : ", err);
            }); 
            // Video.createLocalVideoTrack({
            //     deviceId: { exact: device.deviceId },
            //     enabled: true,
            // }).then(async function (localVideoTrack) {
            //     console.log("[VideoDevices] before unpublic : ", localParticipant.videoTracks.values());
            //     // const tracks = Array.from(localParticipant.videoTracks.values()).map((publication) => publication.track);
            //     // await localParticipant.unpublishTracks(tracks);
            //     // console.log(localParticipant.identity, " removed track: ", tracks[0].kind);
            //     // console.log("[VideoDevices] after unpublic : ", localParticipant.videoTracks.values());
            //     let publication = await localParticipant.publishTrack(localVideoTrack);
            //     console.log("[VideoDevices] after public : ", localParticipant.videoTracks.values());
            //     console.log(localParticipant.identity, " added track: ", localVideoTrack.kind, publication);
            // }).catch((error) => {
            //     console.error("[VideoDevices] createLocalVideoTrack failed : ", error);
            // })
        }
    }

    stopStreamedVideo = async (videoElem) => {
        const stream = videoElem.srcObject;
        if(stream) {
            const tracks = stream.getTracks();
            tracks.forEach(async (track, index) => {
              await track.stop();
            });
            videoElem.srcObject = null;
        }
      }

    handleOpenMenu(event) {
        const {cameraMode} = this.state;
        const {switchDisabled} = this.props
        if (switchDisabled) {
            return
        }

        if(isMobile()) {
            let self = this;
            const changeMode = cameraMode === 'Rear Mode' ? 'Face Mode' : 'Rear Mode';
            this.setState({ cameraMode: changeMode}, () => {
                self.updateVideoDevice(changeMode)
            });
        } else {
            this.setState({ anchorEl: event.currentTarget});
        }
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null});
    };


    render() {
        let { devices, anchorEl } = this.state;
        return (
            <React.Fragment>
                <Tooltip title="Change Camera" arrow>
                    <IconButton className={`${isMobile() ? "inactive-button-mobile m-2" : "inactive-button m-2"}`} onClick={(e) => this.handleOpenMenu(e)}>
                        <SwitchCameraIcon />
                    </IconButton>
                </Tooltip>
                {anchorEl && 
                    <Menu id="simple-menu-video" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.handleCloseMenu()}>
                        {devices.map((device, index) => <MenuItem key={index+'itme'}  className="menuEditItem" onClick={() => this.updateVideoDevice(device.deviceId)}>{device.label}</MenuItem> )}
                        <MenuItem key={'cancel'}  className="menuEditItem" onClick={() => this.handleCloseMenu()}>Cancel</MenuItem>
                    </Menu>
                }
            </React.Fragment>
        );
    }
}


export default VideoDevices;
