import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import Slider from '@mui/material/Slider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

/* eslint-disable no-console */

export default function RctPDFViewer(
  {
    fileUrl, initPageScale, fullPages, openPDFViewer, post
  }
) {
  const [canvasBackground, setCanvasBackground] = useState(null);
  const [displayAll, setDisplayAll] = useState(Boolean(fullPages));
  const [numPages, setNumPages] = useState(null);
  const [pageHeight, setPageHeight] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [pageScale, setPageScale] = useState(initPageScale);
  const [pageWidth, setPageWidth] = useState(null);
  const [render, setRender] = useState(true);
  const [renderAnnotationLayer, setRenderAnnotationLayer] = useState(true);
  const [renderForms, setRenderForms] = useState(true);
  const [renderMode, setRenderMode] = useState('canvas');
  const [renderTextLayer, setRenderTextLayer] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onDocumentLoadProgress = useCallback((progressData) => {
    console.log(
      'Loading a document',
      progressData.total ? progressData.loaded / progressData.total : '(unknown progress)',
    );
    setLoading(true);
  }, []);

  const onDocumentLoadSuccess = useCallback((document) => {
    console.log('Loaded a document', document);
    const { numPages: nextNumPages } = document;
    setLoading(false)
    setNumPages(nextNumPages);
    setPageNumber(1);
  }, []);

  const onDocumentLoadError = useCallback((error) => {
    console.error(error);
    setLoading(false)
    setError(error);
  }, []);

  const onPageRenderSuccess = useCallback((page) => console.log('Rendered a page', page), []);
  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const handleSliderChange = (event, newValue) => {
    console.log("newValue: ", newValue);
    setPageScale(newValue);
  };

  const changeScale = useCallback(
    (offset) => setPageScale((prevScale) => (prevScale) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  function getPageProps() {
    return {
      canvasBackground,
      className: 'custom-classname-page',
      height: pageHeight,
      onClick: (event, page) => openPDFViewer(post),
      onRenderSuccess: onPageRenderSuccess,
      renderAnnotationLayer,
      renderForms,
      renderMode,
      renderTextLayer,
      scale: pageScale,
      width: pageWidth,
      customTextRenderer: (textItem) =>
        textItem.str.split('ipsum').reduce(
          (strArray, currentValue, currentIndex) =>
            currentIndex === 0
              ? [...strArray, currentValue]
              : [
                ...strArray,
                // eslint-disable-next-line react/no-array-index-key
                <mark key={currentIndex}>ipsum</mark>,
                currentValue,
              ],
          [],
        ),
    };
  }

  const pageProps = getPageProps();
  console.log("pageProps :", pageProps);

  const documentProps = {
    file: fileUrl,
    options,
  };
  return (
    <div className='react-pdf-panel'>
      <div className="ls-react-pdf">
        <Document
          {...documentProps}
          className="custom-classname-document"
          onLoadError={onDocumentLoadError}
          onLoadProgress={onDocumentLoadProgress}
          onLoadSuccess={onDocumentLoadSuccess}
          onSourceError={onDocumentLoadError}
          renderMode="svg" 
        >
          {render &&
            (displayAll ? (
              Array.from(new Array(numPages), (el, index) => (
                <Page
                  {...pageProps}
                  key={`page_${index + 1}`}
                  inputRef={
                    pageNumber === index + 1 ? (ref) => ref && ref.scrollIntoView() : null
                  }
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))
            ) : (
              <Page {...pageProps} pageNumber={pageNumber || 1} renderTextLayer={false} renderAnnotationLayer={false}/>
            ))}
        </Document>


      </div>
      {!loading && !error &&
        <div className={`bottom-absolute`}>
          <div className={`d-flex align-items-center ${displayAll ? 'justify-content-center' : 'justify-content-between'}`}>
            <div className='pageScale'>
              <div className='d-flex align-items-center'>
                <IconButton disabled={pageScale <= 0.1} fontSize="small" onClick={() => changeScale(-0.01)}>
                  <RemoveIcon />
                </IconButton>
                <Slider
                  aria-label="Volume"
                  defaultValue={initPageScale}
                  step={0.01}
                  min={0.1}
                  max={2}
                  value={typeof pageScale === 'number' ? pageScale : 0.01}
                  onChange={handleSliderChange}
                />
                <IconButton disabled={pageScale > 2} fontSize="small" onClick={() => changeScale(+0.01)}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            {displayAll || (
              <div className="page-controls" >
                <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
                  <NavigateBeforeIcon />
                </IconButton>
                <span>{`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}</span>
                <IconButton disabled={pageNumber >= numPages} onClick={nextPage}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            )}

          </div>
        </div>
      }


    </div>
  );
}