import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import queryString from 'query-string';
import {
	notifyGroup, 
	notifyUser, 
	hideVideoRoomPanel, 
	getVideoCallDetails,
	connectTwilioVideoCall,
	connectTwilioVideoCallSuccess,
	sendGroupVideoCallResponse,
	getSelectedVideoChat,
	removeVideoToken,
	handleChangeMode,
	handleChangeBottomTab
} from "Actions"
import { connect } from 'react-redux';
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import VideoChatRoom from "Components/LifesherpaContacts/VideoChatRoom"
import LoadingVideoUI from "./LoadingVideoUI";
import TwilioVideo  from "twilio-video";
import {isMobile} from '../../../helpers/helpers';
import { CallKitVoip } from 'callkit-voip-capacitor-plugin';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

class VideoChat extends Component {
	state = { 
		romeName:'',
		room:null,
		video:'',
		channelTitle:"",
		loading:false,
		realTimeData: null,
		mediaAvailable : {audio: false, video: false},
		dominantSpeakerIdentity: null,
		bandwidthProfile: {
			video: {
				mode: 'grid',
				dominantSpeakerPriority: 'high'
			}
		},
		abortController: new AbortController()
	}

	async componentDidMount() {
        let {videoToken,selectedVideoChat, videoCallDetails} = this.props;
		console.log(" selectedVideoChat -->",selectedVideoChat);

		document.addEventListener('nativeBackClick', this.handleBackButton);

		// getting available device media tracks
		const mediaAvailable = {audio: false, video: false};
        // connect to twilio video room
		let self = this;
		navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((audioStream) =>  {
			let audioTracks = audioStream.getAudioTracks();
			console.log("[VideoChat] audioTracks : ", audioTracks);
			let audioTrack = TwilioVideo.LocalAudioTrack(audioTracks[0], {name: audioTracks[0].label});
			mediaAvailable.audioTrack = audioTrack;
			mediaAvailable.audio = true;
			self.getAudioPermissionStatus(mediaAvailable);
			
		}).catch((err) => {
			console.log("microphone not available --> ", err);
			mediaAvailable.audio = false;
			self.getAudioPermissionStatus(mediaAvailable)
		});
	}

	getAudioPermissionStatus = (mediaAvailable) => {
		let self = this;
		const constraint = isMobile() ? { facingMode: "user" } : true;
		console.log("[VideoChat] video constraint ", constraint);
		navigator.mediaDevices.getUserMedia({audio: false, video: true}).then((videoStream) =>  {
			let videoTracks = videoStream.getVideoTracks();
			console.log("[VideoChat] videoTracks : ", videoTracks);
			const trackName = isMobile() ? "Face Mode": videoTracks[0].label;
			let videoTrack = TwilioVideo.LocalVideoTrack(videoTracks[0], {name: trackName});
			mediaAvailable.videoTrack = videoTrack;
			mediaAvailable.video = true;
			self.handleVidoeRoomConnect(mediaAvailable)
		}).catch((err) => {
			mediaAvailable.video = false;
			self.handleVidoeRoomConnect(mediaAvailable);
			console.log("camera not available --> ", err);
		}); 
	}

	handleVidoeRoomConnect = (mediaAvailable) => {
		
		let {videoToken,selectedVideoChat, videoCallDetails} = this.props;
		const {bandwidthProfile} = this.state
        // // connect to twilio video room
		this.props.connectTwilioVideoCall();
		TwilioVideo.connect(videoToken, {...mediaAvailable, name: selectedVideoChat.name, dominantSpeaker: true, bandwidthProfile}).then(async (room) => {
			console.log(" fetched room details -->",room);
			this.handleSendNotifiyUsers();
			
            if(mediaAvailable.videoTrack) {
				const publication = await room.localParticipant.publishTrack(mediaAvailable.videoTrack);
                console.log("[VideoChat] video publication : ", publication);
			}
			if(mediaAvailable.audioTrack) {
				const publication = await room.localParticipant.publishTrack(mediaAvailable.audioTrack);
                console.log("[VideoChat] audio publication : ", publication);
			}
			room.on('dominantSpeakerChanged', participant => {
				console.log(" dominantSpeakerChanged ------------>", participant && participant.identity);
				if(participant && participant.identity) {
					this.setState({dominantSpeakerIdentity : participant.identity})
				} 
			});
			this.setState({room:room,selected:'videoCall',romeName: selectedVideoChat.name, mediaAvailable: mediaAvailable})
		}).catch((err) => {
			console.error(' room error --> ',err);
			this.handleGoToMainPage();
		});
	}

	handleSendNotifiyUsers = () => {
		let {selectedVideoChat, videoCallDetails} = this.props;
		let videoCallRoomDetails = localStorage.getItem("videoCallRoomDetails")
		videoCallRoomDetails = videoCallRoomDetails ?  JSON.parse(videoCallRoomDetails) : null;
		console.log("[VideoChat] videoCallRoomDetails --> ", videoCallRoomDetails);
		let members=[];
		let groupId = "";
		if(videoCallRoomDetails) {
			// Notifying Member
			if(videoCallRoomDetails.members){
				videoCallRoomDetails.members.map((mb)=>members.push(mb.userId));
				groupId = videoCallRoomDetails.id;
				this.props.notifyGroup(videoCallRoomDetails.name,videoCallRoomDetails.id,members, this.state.abortController);
			} else if(videoCallRoomDetails.userId) {
				this.props.notifyUser(videoCallRoomDetails.userId,videoCallRoomDetails.name, this.state.abortController);
			}
		} else {
			// Fetching Room Details
			if(videoCallDetails && videoCallDetails.roomName == selectedVideoChat.name) {
				this.props.connectTwilioVideoCallSuccess();
			} else {
				this.props.getVideoCallDetails(selectedVideoChat.name, this.state.abortController);
			}
		}
	}

	handleLogout=(isGroupCall)=>{
		 const {room} = this.state;
		if (room) {
			room.localParticipant.videoTracks.forEach(publication => {
				publication.track.stop();
				publication.unpublish();
			});
			room.localParticipant.audioTracks.forEach(publication =>{
				publication.track.stop();
				publication.unpublish();
			});
			room.off('dominantSpeakerChanged', () => {
				console.log(" dominantSpeakerChanged listeners off ------------>");
			});
			room.disconnect();
		}
		this.sendVidoeCallResponse("cancel", null, isGroupCall);
		this.setState({room:null,selected: "",video:'',romeName:''});
		this.handleGoToMainPage()
		
	}

	componentWillUnmount() {
		const {room} = this.state;
		const {videoCallDetails} = this.props;
		if (room) {
			room.localParticipant.videoTracks.forEach(publication => {
				publication.track.stop();
				publication.unpublish();
			});
			room.localParticipant.audioTracks.forEach(publication =>{
				publication.track.stop();
				publication.unpublish();
			})
			room.disconnect();
		}
		this.props.removeVideoToken();
		this.sendVidoeCallResponse("cancel", null, videoCallDetails && videoCallDetails.group);
		// Abort the request if it's in progress when the component unmounts
        this.state.abortController.abort();

        // Clean up the event listener
        document.removeEventListener('nativeBackClick', this.handleBackButton);
	}

    handleBackButton = () => {
        // Abort the Axios request on back button press
        this.state.abortController.abort();
		this.handleGoToMainPage()
		console.log("handle vide call back:")
    }

	handleGoToMainPage = () => {
		this.props.removeVideoToken();
		const params = this.getParams();
		if(params && params.room) {
			this.props.getSelectedVideoChat(null);
			this.props.handleMessageForRoom(true)
			localStorage.removeItem("videoCallRoomDetails")
			setTimeout(async() => {
			    this.props.handleMessageForRoom(false);
				if(this.props.location && this.props.location.pathname == "/lifesherpa/videocall" && isMobile()) {

					if (Capacitor.getPlatform() == "android") {
						const isScreenLocked = await CallKitVoip.isScreenLocked()
						console.log("isScreenLocked: ", isScreenLocked)
						if (isScreenLocked.isLocked) {
							App.exitApp()
						} else {
							this.props.handleChangeBottomTab({tabMode: "Contacts", selectedClientMenuItem: null,  showFallbackScreen: false });
							this.props.navigate("/app/contacts");
						}
					} else {
						// this.props.handleChangeMode("Contacts");
						this.props.handleChangeBottomTab({tabMode: "Contacts", selectedClientMenuItem: null,  showFallbackScreen: false });
						this.props.navigate("/app/contacts");
					}
				} else if (this.props.location && this.props.location.pathname == "/lifesherpa/videocall") {
					window.close();
					this.props.navigate("/app/lsdashboard")
				}
			}, 4000)
		} 
		this.props.hideVideoRoomPanel();
	}

	getParams = () => {
		let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params
	}

	sendVidoeCallResponse = (action, members, isGroupCall ) => {
		const {romeName} = this.state;
		const {groupId} = this.props;
		let recipients = members ? { recipientId: members } : null;
		if (romeName) {
			this.props.sendGroupVideoCallResponse(romeName, action, recipients);
			console.log("isGroupCall :", isGroupCall);
		}
	}

    render() { 
		const {  selectedVideoChat, windowWidth, contactList, videoCallDetails, videoConnectLoading, safeAreaParams } = this.props;
		const { room, romeName, selected, loading,dominantSpeakerIdentity} = this.state;
		console.log("[VideoChat] videoTokenLoading : ",videoConnectLoading);
        return (
			<div id="video_call_back"> 
				 <LifeSherpaLoading loading={loading}/>
                    {!videoConnectLoading && selected == 'videoCall' && room ?
						<VideoChatRoom 
							roomName={romeName} 
							videoCallDetails={videoCallDetails} 
							dominantSpeakerIdentity={dominantSpeakerIdentity}
							room={room} 
							sendVidoeCallResponse={this.sendVidoeCallResponse}
							handleLogout={(e)=>this.handleLogout(e)} 
							contactList={contactList} 
							windowWidth={windowWidth}
							popupThemeClass={this.props.popupThemeClass}
							mediaAvailable = {this.state.mediaAvailable}
							safeAreaParams={safeAreaParams}
						 />
                        :
						<LoadingVideoUI text={"Fetching room details..."}/>
                    }	
             </div> 
		);
    }
}
 
const mapStateToProps = ({Contacts,authUser, settings}) => {
	const {userRole}=authUser;
	const { popupThemeClass, safeAreaParams } = settings; 
	const {videoToken, loading, selectedVideoChat, contactList, videoCallDetails, videoConnectLoading, inProgressVideoRoomDetail}=Contacts
	return {videoToken,userRole, loading, selectedVideoChat, contactList, videoCallDetails , videoConnectLoading, popupThemeClass, inProgressVideoRoomDetail, safeAreaParams};
};

export default withRouter(connect(mapStateToProps, {
	notifyGroup,
	notifyUser,
	hideVideoRoomPanel, 
	getVideoCallDetails,
	connectTwilioVideoCall,
	connectTwilioVideoCallSuccess,
	sendGroupVideoCallResponse,
	getSelectedVideoChat,
	removeVideoToken,
	handleChangeMode,
	handleChangeBottomTab
})(VideoChat));
