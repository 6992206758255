import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { isMobile, requireSignIn, scrollFunctionByClass } from '../helpers/helpers';
// rct theme provider
import RctThemeProvider from './RctThemeProvider';
import SuccessComponent from '../components/LifesherpaiFrame/SuccessComponent';
import UIFallbackScreen from '../components/UIFallbackScreen';
// Main App
import RctDefaultLayout from './DefaultLayout';
// App sign-in
import Signin from './SigninFirebase';
import AppSignUpLifesherpa from './LifesherpaSignup';
import AppSignInWithToken from './SignInFirebaseToken';
import AppInitialLaunchScreen from '../components/LaunchScreen/InitialLaunchScreen';
import AppSuccess from '../components/SuccessComponent/SuccessScreen';
import Auth0logout from './Auth0logout';
import AnalyticsLogoutScreen from "./AnalyticsLogout";
import AppLoginSuccess from '../components/LoginComponent/LoginSuccess';
import Alerts from '../components/Alerts';
import { App as CapacitorApp } from '@capacitor/app';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Browser } from '@capacitor/browser';
import { saveSafeAreaParams } from '../actions';
// Async components
import {
    AsyncMyActivityPreview,
    AsyncAuthoLogin,
    AsyncAuth0RedirectSignIn,
    AsyncLifesherpaVideoCall,
} from '../components/AsyncComponent/AsyncComponent';

import {
    clearActivityDataOnClose,
    hideStartWorkflowPanel,
    handleUserWorkflowPanel,
    handleChangeBottomTab,
    showClientPanel,
    handleShowFallbackScreen,
    removeSelectedUser
} from '../actions';

import withRouter from '../router';
import LifeSherpaLoading from '../components/LifeSherpaLoading';
import _ from 'lodash';
import AppTTSManager from '../components/AppTTSManager';

const InitialPath = ({ element: Element, authUser, ...rest }) => {
    return authUser ? <Element {...rest} /> : <Navigate to="/launchscreen" state={{ from: rest.location }} />;
};

class App extends Component {
    state = {
        userAuthenticated: undefined,
        hasError: false,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.log("%cThis is something that needs to be fixed", "color:blue");
        console.log({ error, info });
    }

    componentDidMount() {
        this.removeWidgetConfigration();
        this.props.handleUserWorkflowPanel(false, null);

        requireSignIn()
            .then(() => this.setState({ userAuthenticated: "AUTHENTICATED" }))
            .catch(() => this.setState({ userAuthenticated: 'AUTH_REQUIRED' }));

        this.props.clearActivityDataOnClose();
        this.props.hideStartWorkflowPanel();

        window.onscroll = () => scrollFunctionByClass("rct-scroll");
        const redirectURLs = {
         '/app/lsdashboard': [
               '/app/lsdashboard/course',
               '/app/lsdashboard/launchpad',
               '/app/usercharts', 
               '/app/configurator', 
               '/app/lms'
         ],
         '/app/lsdashboard/activities/history': ['/app/lsdashboard/activities/history/details'],
         '/app/workflows/history': ['/app/workflows/history/details'],
         '/app/contacts':['/app/contacts/chat', '/app/contacts/video-call/groups', '/app/contacts/calllogs'],
         '/app/contacts/video-call/groups':['/app/contacts/video-call/group', '/app/contacts/video-call/new-group'],
         '/app/lsdashboard/client': [
            '/app/lsdashboard/client/activities',
            '/app/lsdashboard/client/workflows',
            '/app/lsdashboard/client/resources',
            '/app/lsdashboard/client/rewards',
            '/app/lsdashboard/client/activity/detail', 
         ],
         'close':[
            '/launchscreen', 
            '/app/lsdashboard', 
            '/app/lsdashboard/activities', 
         ],
         'Home':[
            '/app/lsdashboard/dashboard',
            '/app/workflows',
            '/app/workflows/list',
            '/app/workflows/workflow_summary_report',
            '/app/lsdashboard/clients',
            '/app/contacts',
            '/app/lsdashboard/more'
         ],
         "prevUrl": [
            '/app/lsdashboard/activities/history',
            '/app/workflows/history',
            '/app/lsdashboard/chat',
            '/app/lsdashboard/client',
            '/app/lsdashboard/client/activities/assign'
         ],
         '/launchscreen': ['/signinEmail', '/logout', '/capacitor']
      };

      const advancePageIds = [
         'actiivties-filter-popup', 
         'bulletin-post-viewer-popup', 
         'resources-filter-popup', 
         'activity-confirm-form-popup', 
         'activity-editor-drawer',
         'add-members-to-video-group',
         'user-workflows-popup-screen',
         'workflow-users-to-assign-popup-screen',
         'workflow-assigned-users-popup-screen',
         'workflow-history-popup-screen',
         'video-call-history-popup-screen',
         'organizations-filter-screen',
         'contacts-filter-popup',
         'activity-history-legends-popup',
         'contact-user-chat-screen',
         'activity-calender-popup',
         "simple-menu-user-block",
         "simple-menu-user-text",
         "simple-menu-video",
         "simple-menu-home",
         "simple-menu-on-client",
         "simple-menu-workflow",
         "simple-menu-activity-detail",
         "simple-menu-clients-panel",
         "notification_provide_reward", 
         "notification_alert", 
         "notification_popup", 
         "notification_exe_activity", 
         "notification_activity_completion_confirm",
         "video_call_back",
         "landing-page-my-rewards-panel",
         "client-alert-detail-popup",
         "provided-rewards-popup",
         "video-call-participants-popup"
      ];
      
        // App URL listener
        CapacitorApp.addListener("appUrlOpen", async ({ url }) => {
            if (url.includes("/logout") && url.includes("/capacitor")) {
                window.location.href = `${window.location.origin}/launchscreen`;
                await Browser.close();
            }
        });

        // Native back button listener
        // document.addEventListener('backButton', () => {
        CapacitorApp.addListener('backButton', ({ canGoBack }) => {
         const {clientPanel, noticePanel, showClientDashBaord, startActivityPanel, passwordDialog, currentStatusPopup} = this.props;
         if((noticePanel || showClientDashBaord || startActivityPanel || passwordDialog || currentStatusPopup || advancePageIds.find(id => document.getElementById(id)))) {
             console.log("native back event dispatch")
             const stopRoutesBack = new Event("nativeBackClick");
             document.dispatchEvent(stopRoutesBack);
             return;
          }

          let isBackURLFound = false;
          console.log("this.props.location.pathname :", this.props.location.pathname);
         
          for(const action in redirectURLs) {
             if(redirectURLs[action].includes(this.props.location.pathname)) {
                isBackURLFound = true;
                console.log("action :", action);
                if(action === 'close') {
                   CapacitorApp.exitApp();
                } else if(action === 'Home') {
                    this.goToHomePage();
                } else if(action === 'prevUrl') {
                   let prevUrl = localStorage.getItem('prevUrl');
                    if(prevUrl) {
                        localStorage.removeItem('prevUrl');
                        if(this.props.location.pathname === "/app/lsdashboard/client") {
                            this.props.showClientPanel(false);
                        }
                        if(this.props.location.pathname === "/app/lsdashboard/chat") {
                            this.props.removeSelectedUser();
                        }
                        this.props.navigate(prevUrl);
                    } else if(["/app/lsdashboard/client/activities/assign", "/app/lsdashboard/client", "/app/lsdashboard/activities/history"].includes(this.props.location.pathname)) {
                        this.goToClientsPage();
                    } else {
                        this.goToHomePage();
                    }
                } else {
                   this.props.navigate(action);
                }
                break;
             }
          }
          
          if(!isBackURLFound) {
            CapacitorApp.exitApp();
            //  if(!canGoBack){
            //    CapacitorApp.exitApp();
            //  } else {
            //     window.history.back();
            // }
          }
        });

        // Safe area insets
       this.setSafeArea();
    }
    goToHomePage() {
        this.props.handleChangeBottomTab({tabMode: "Home Page", selectedClientMenuItem: "/app/lsdashboard/activities",  showFallbackScreen: true });
        this.props.navigate('/app/lsdashboard/activities');
        this.triggerRenderPage();
    }

    goToClientsPage() {
        this.props.handleChangeBottomTab({tabMode: "My Clients", selectedClientMenuItem: "/app/lsdashboard/client",  showFallbackScreen: true });
        this.props.navigate('/app/lsdashboard/clients');
        this.triggerRenderPage();
    }

    // Function to trigger page rendering with debounce
    triggerRenderPage = _.debounce(() => {
        if (this.props.clientPanel) {
            this.props.showClientPanel(false);
        }
        this.props.removeSelectedUser();
        // this.props.navigate(path);
        this.props.handleShowFallbackScreen(false);
    }, 500);


    setSafeArea() {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            let safeAreaData = {};
            for (const [key, value] of Object.entries(insets)) {
                document.body.style.setProperty(`--safe-area-${key}`, `${value}px`);
                safeAreaData[key] = value;
            }
            this.props.saveSafeAreaParams(safeAreaData);
        });

        SafeArea.addListener('safeAreaChanged', ({ insets }) => {
            for (const [key, value] of Object.entries(insets)) {
                document.body.style.setProperty(`--safe-area-${key}`, `${value}px`);
            }
        });
    }

    removeWidgetConfigration = () => {
        localStorage.removeItem("widgetApp");
    };

    render() {
        const { location, user, userPrivacyPolicyData } = this.props;
        const { userAuthenticated, hasError } = this.state;

        if (hasError) {
            return <UIFallbackScreen />;
        }

        let homeRedirectURL = isMobile() ? '/app/lsdashboard/activities' : '/app/lsdashboard';

        if (userAuthenticated) {
            if (location.pathname === '/') {
                return user && userPrivacyPolicyData && userPrivacyPolicyData.status ? <Navigate to={homeRedirectURL} /> : <Navigate to="/launchscreen" />;
            }
        } else {
            return <LifeSherpaLoading loading={true}/>
        }

        return (
            <RctThemeProvider>
                {isMobile() ? <AppTTSManager /> : <></>}
                {<NotificationContainer />}
                <Routes>
                    <Route path="/app/*" element={<InitialPath element={RctDefaultLayout} authUser={user && userPrivacyPolicyData && userPrivacyPolicyData.status} {...this.props} />} />
                    <Route path="/privacy-policy" element={<Alerts {...this.props} />} />
                    <Route path="/launchscreen" element={<AppInitialLaunchScreen {...this.props} />} />
                    <Route path="/auth0loginsuccess" element={<AppSuccess {...this.props} />} />
                    <Route path="/auth0login" element={<AsyncAuthoLogin {...this.props} />} />
                    <Route path="/signin" element={<AsyncAuth0RedirectSignIn {...this.props} />} />
                    <Route path="/activity/preview" element={<AsyncMyActivityPreview {...this.props} />} />
                    <Route path="/signinEmail" element={<Signin {...this.props} />} />
                    <Route path="/loginsuccess" element={<AppLoginSuccess {...this.props} />} />
                    <Route path="/signinwithtoken" element={<AppSignInWithToken {...this.props} />} />
                    <Route path="/signuplifesherpa" element={<AppSignUpLifesherpa {...this.props} />} />
                    <Route path="/logout" element={<Auth0logout {...this.props} />} />
                    <Route path="/success" element={<SuccessComponent {...this.props} />} />
                    <Route path="/analyticslogout" element={<AnalyticsLogoutScreen {...this.props} />} />
                    <Route path="/lifesherpa/videocall" element={<AsyncLifesherpaVideoCall {...this.props} />} />
                </Routes>
            </RctThemeProvider>
        );
    }
}

// Map state to props
const mapStateToProps = ({ authUser, clientsList, activitiesList, noticesReducer }) => {
    const { user, userPrivacyPolicyData, passwordDialog, currentStatusPopup } = authUser;
    const { clientPanel, showClientDashBaord } = clientsList;
    const { showCamera, startActivityPanel } = activitiesList;
    const { noticePanel } = noticesReducer;

    return { user, userPrivacyPolicyData, clientPanel, showCamera, startActivityPanel, noticePanel, showClientDashBaord, passwordDialog, currentStatusPopup };
};

export default withRouter(connect(mapStateToProps, {
    clearActivityDataOnClose,
    hideStartWorkflowPanel,
    saveSafeAreaParams,
    handleUserWorkflowPanel,
    handleChangeBottomTab,
    showClientPanel,
    handleShowFallbackScreen,
    removeSelectedUser
})(App));
