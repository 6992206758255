import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from '@mui/material/Avatar';
import ParticipantsListItem from "./ParticipantsListItem";

class ParticipantsList extends Component {
    state = { 
        isLocalHostOfGroup: false
    }

    componentDidMount() {
        const {localParticipant, realTimeData} = this.props;
        let localParticipantUserId = localParticipant ? localParticipant.identity.split("--")[0]:"";
         if(realTimeData && realTimeData.group) {
            let localUser = realTimeData.members.find(user => user.userId == localParticipantUserId);
            if(localUser && localUser.host) {
                this.setState({isLocalHostOfGroup: true});
            }
        }
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleNativeBack = () => {
        console.log("native back clicked for provided rewards popup ........");
        this.props.handleParticipantListPopup(false)
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleOpenScreen = (indetity) => {
        this.props.handleParticipantListPopup(false);
        this.props.handlePingParticipantToMainScreen(indetity);
    }

    render() { 
        let {isLocalHostOfGroup} = this.state;
		const {  participants, showParticipantListPopup } = this.props;
        console.log(" participants --> ", participants);
        return (
             <div>
                {showParticipantListPopup&&
                    <Dialog
                        open={showParticipantListPopup}
                        onClose={()=>this.props.handleParticipantListPopup(false)}
                        className={`${this.props.popupThemeClass} video-room-participant-list`}
                        id="video-call-participants-popup"
                    >
                    <DialogTitle>
                        <div  className="d-flex align-items-center template-header justify-content-between all-clients-for-grooup pl-3 py-1">
                            <div className="header-title">Participants</div>
                            <div className="d-flex align-items-center">
                                <IconButton onClick={()=>this.props.handleParticipantListPopup(false)} className=""><CloseIcon /></IconButton>
                            </div>
                        </div>
                </DialogTitle>
                    <DialogContent className="p-0 py-0">
                    <ul className="list-unstyled m-0 list-sherpa-activity add-group-contact-list-popup px-0 pt-1 " data-testid="all-contacts-list">
                        {participants && participants.map((participant,index)=>(
                            <ParticipantsListItem
                                key={index}
                                keyIndex = {index}
                                participant={participant}
                                handleRemoteParticipantMute={this.props.handleRemoteParticipantMute}
                                handleRemoteParticipantUnMute={this.props.handleRemoteParticipantUnMute}
                                handleRemoteParticipantRemove={this.props.handleRemoteParticipantRemove}
                                realTimeData={this.props.realTimeData}
                                isLocalHostOfGroup={isLocalHostOfGroup}
                                handleOpenScreen={this.handleOpenScreen}
                            />
                        ))}
                    </ul>
                    </DialogContent>
                  
                </Dialog>    
                }
          </div>
             );
    }
}
 

export default ParticipantsList;
