import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getRewards, buyRewardByRewardId, getPendingRewards, addtoFavourite, getreceivedRewards, confirmRewards } from '../../actions'
import { Label } from 'reactstrap'
import { Button, IconButton } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Badge from '@mui/material/Badge'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbars } from 'react-custom-scrollbars';
import LifeSherpaLoading from "../LifeSherpaLoading";
import moment from 'moment'
import RewardsProvidedTimes from "./RewardsProvidedTimes";
import CustomScrollbars from '../CustomScrollbars';
import { isMobile } from '../../helpers/helpers';


class RewardsPage extends Component {
    state = {
        rewardsOption: ["BUY NEW REWARDS", "REWARDS I'M WAITING ON", "REWARDS I'VE RECEIVED"],
        selRewardOpt: null,
        buyAlert: false,
        id: null,
        reward: null,
        showProvidedTimes: false,
        selectedReward: null
    }
    
    componentDidMount() {
        this.props.getRewards(true)
        this.props.getPendingRewards(true)
        this.props.getreceivedRewards(true)
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }


    handleNativeBack = () => {
        console.log("native back clicked for buy rewards popup ........");
        this.setState({ buyAlert: false, id: null, reward: null })
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleReward = (value) => {
        this.setState({ selRewardOpt: value })
    }

    buyReward = (id) => {
        const auth_uid = localStorage.getItem('auth_uid');
        const uid = localStorage.getItem('uid');
        const creditScore = auth_uid === uid ? this.props.credit : this.props.userCredit;
        const credit = creditScore - this.state.reward.cost;
        this.props.buyRewardByRewardId(id, credit)
        this.setState({ buyAlert: false, id: null, reward: null })
    }

    getScrollHeight() {
        const { selRewardOpt } = this.state;
        if (localStorage.getItem("widgetApp")) {
            return "calc(100vh - 106px)";
        } else if (selRewardOpt) {
            return '580px';
        } else if(this.props.isSidebarEnable) {
            return "calc(100vh - 135px)";
         }  else {
            return '630px';
        }

    }


    getDateDiffence(datestring) {
        const years = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "years")
        const months = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "months")
        const days = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "days")
        const hours = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "hours")
        const minutes = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "minutes")
        const actualvalue = years > 0 ? `${years}y` : months > 0 ? `${months}mo` : days > 0 ? `${days}d` : hours > 0 ? `${hours}h`: `${minutes}m`
        return actualvalue
    }

    handleCloseProvidedTimes = () => {
        this.setState({ showProvidedTimes: false, shoppingList: null })
    }

    handleOpenProvidedTimes = (list) => {
        this.setState({ showProvidedTimes: true, selectedReward: list })
    }

    render() {
        let { selRewardOpt, reward, rewardsOption } = this.state;
        const { recivedReward, pendingReward, buyReward, loading, authUserImage, authUserName, clientImage, clientName, selectedMemberId } = this.props;
        let totalCount = 0;
        const auth_uid = localStorage.getItem('auth_uid');
        const uid = localStorage.getItem('uid');
        const profileImage = auth_uid === uid ? authUserImage : clientImage;
        const displayName = auth_uid === uid ? authUserName : clientName;
        const rewardsTitle = auth_uid === uid ? "My Rewards" : "Rewards";
        const creditScore = auth_uid === uid ? this.props.credit : this.props.userCredit;
        const isLoggedUser = auth_uid === uid ? true : false;
        if (pendingReward) {
            pendingReward.map((rwd) => {
                totalCount = totalCount + rwd.shopping.length
            })
        }
        if (!isLoggedUser) {
            rewardsOption = [`ALL REWARDS`, `REWARDS ${displayName.toUpperCase()} IS WAITING ON`, `REWARDS ${displayName.toUpperCase()} HAS RECEIVED`];
        }

        return (
            <div data-testid="landing-page-my-rewards-panel" id="landing-page-my-rewards-panel" className="rewards-container lifesherpa-session-theme-support">
                <LifeSherpaLoading loading={loading} />
                {/* <div className="d-flex reward-header bg-lifesherpa">
                    
                    <h4 className="my-auto font-lg" data-testid="rewards-client-name">{rewardsTitle}</h4>
                    <div className="d-flex align-items-center ml-auto my-auto mr-2 font-lg">
                        <img className="mr-1" src={require("Assets/img/diamond_icon.png")} alt="project logo" width="24" height="24" />
                        <div data-testid="rewards-panel-credit">{creditScore ? creditScore : 0}</div>
                    </div>
                </div> */}
                {selRewardOpt &&
                    <div className="d-flex align-items-center justify-content-between px-1 py-1 border-top bg-lifesherpa">
                        <IconButton title="Back To List" onClick={() => this.setState({ selRewardOpt: null })}><ArrowBackOutlinedIcon /></IconButton>
                        <div className="" style={{ fontWeight: 550 }} >{selRewardOpt}</div>
                        <div style={{ width: "45px" }}></div>
                    </div>
                }

                <CustomScrollbars
                    className="rct-scroll list-sherpa-activity resources-list-background"
                    autoHide
                    style={{ height: this.getScrollHeight() }}
                    hideTracksWhenNotNeeded={isMobile()}
                >
                    {selRewardOpt === rewardsOption[0] ?
                        <div>
                            {this.state.buyAlert &&
                                <Dialog
                                    fullScreen={false}
                                    open={this.state.buyAlert}
                                    onClose={() => this.setState({ buyAlert: false, id: null, reward: null })}
                                    fullWidth={true}
                                    maxWidth="xl"
                                    className={`${this.props.popupThemeClass}`}
                                    aria-labelledby="responsive-dialog-title"
                                    PaperProps={
                                        { style: { maxWidth: "450px", height: 800 + "px" } }
                                    }
                                >
                                    <div className="d-flex align-items-center bg-lifesherpa template-header justify-content-between pl-3 py-2 pr-1">
                                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Buy Reward</Label>
                                        <IconButton className="text-white" title="Close Buy Reward" onClick={() => this.setState({ buyAlert: false, id: null, reward: null })}><CloseIcon /></IconButton>
                                    </div>
                                    <DialogContent className="pl-4 pr-4">
                                        <h5 className="text-center text-secondary my-auto font-lg ">{"Are you sure you want to buy " + reward.title + " for " + reward.cost + " Gems?"}</h5>
                                        <div className="d-flex justify-content-center text-center my-4 py-4"><img className="img-fluid border" width="auto" height={200 + "px"} src={reward.url} alt="" style={{ borderRadius: 15 + "px" }} /></div>
                                        <h5 className="text-center mt-auto text-secondary my-auto font-lg">{"If you buy this,you will have " + (+this.props.credit - +reward.cost) + " Gems left"}</h5>
                                    </DialogContent>
                                    <DialogActions className="p-4 filter-buttons-styles">
                                        <Button title="Never mind" color="primary" variant="outlined" data-testid="nevermind-buy-reward" className="  w-50 filter-button-cancel" onClick={() => this.setState({ buyAlert: false, id: null, reward: null })}>
                                            Nevermind
                                        </Button>
                                        <Button title="Buy Reward" color="primary" variant="contained" data-testid="buy-reward-button" className=" w-50 filter-button-apply" onClick={() => this.buyReward(this.state.id)}>
                                            Buy
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            }
                            <ul className="list-unstyled mt-3 mx-2" >
                                {buyReward && buyReward.length > 0 ? buyReward.map((opt, index) => (
                                    <li key={index} className="list-card mb-1 justify-content-between align-items-center list-item border-bottom p-3" style={{ fontWeight: 500 }} data-testid={"buy-reward-list-item-"+index}>
                                        {opt.url && opt.url.includes("http") &&
                                            <div className="d-flex w-100 justify-content-center align-items-center">
                                                <img className="img-fluid-ext border" width="auto" src={opt.url} alt="" style={{ borderRadius: 15 + "px", maxHeight: 250 }} data-testid="reward-image-item"/>
                                            </div>
                                        }

                                        <div className="d-flex justify-content-between align-items-center my-2 border-bottom pb-2">
                                            <h3 className="heading-text mb-0" style={{ fontWeight: 550 }}>{opt.title}</h3>
                                            {isLoggedUser ?
                                                <IconButton title={opt.favorite ? "Remove From Favourite" : "Add To Favourite"} onClick={() => this.props.addtoFavourite(opt.id)} >
                                                    {
                                                        opt.favorite ? <StarOutlinedIcon fontSize='large' className='text-warning' /> : <StarBorderIcon fontSize='large' className='text-warning' />
                                                    }
                                                </IconButton>
                                                :
                                                <div>
                                                    {
                                                        opt.favorite ? <StarOutlinedIcon title="Favourite" fontSize='large' className='text-warning' /> 
                                                                : <StarBorderIcon title="Not Favourite" fontSize='large' className='text-warning' />
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className="d-flex justify-content-between align-items-center font-lg pt-2" style={{ fontWeight: 400 }}>
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src={require("Assets/img/diamond_icon.png")} alt="project logo" width="24" height="24" />
                                                <div className="heading-text">{creditScore + "/" + opt.cost}</div>
                                            </div>
                                            {isLoggedUser && (creditScore > opt.cost) ?
                                                <Button title="Buy Reward" color="primary" variant="contained" size="small" style={{ borderRadius: 16 + "px", width: 120, paddingTop: 6 }} onClick={() => this.setState({ id: opt.id, reward: opt, buyAlert: true })}>
                                                    BUY
                                                </Button>
                                                :
                                                <div className="d-flex w-50 pr-1">
                                                    <div style={{ height: 5 + 'px', borderRadius: 2 + 'px', width: `${Math.round(creditScore / opt.cost * 100)}%` }} className={`activity-completed`}></div>
                                                    <div className={`w-}`} style={{ background: 'lightgray', height: 5 + 'px', borderBottomRightRadius: 2 + 'px', borderTopRightRadius: 2 + 'px', width: `${100 - Math.round(creditScore / opt.cost * 100)}%` }}></div>
                                                </div>

                                            }

                                        </div>

                                    </li>))
                                    :
                                    <div className="heading-text my-3 text-center">
                                        Buy Rewards Not Found
                                    </div>
                                }
                            </ul>
                        </div>
                        : selRewardOpt === rewardsOption[1] ?
                            <div>
                                <ul className="list-unstyled mt-3 mx-2" >
                                    {pendingReward && pendingReward.length > 0 ? pendingReward.map((opt, index) => (
                                        opt.shopping.map((op, key) => (
                                            <li key={key} className="list-card mb-1 list-item border-bottom p-3" style={{ fontWeight: 500 }} data-testid={"waiting-reward-list-item-"+index}>
                                                {opt.url && opt.url.includes("http") &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <img className="img-fluid-ext border" width="auto" src={opt.url} alt="" style={{ borderRadius: 15 + "px", maxHeight: 250 }} data-testid="reward-image-item"/>
                                                    </div>
                                                }
                                                <div className="d-flex justify-content-between align-items-center my-2 border-bottom pb-2">
                                                    <h3 className="heading-text mb-0" style={{ fontWeight: 550 }}>{opt.title}</h3>
                                                    {isLoggedUser ?
                                                        <IconButton title={opt.favorite ? "Remove From Favourite" : "Add To Favourite"} onClick={() => this.props.addtoFavourite(opt.rewardId)} >
                                                            {
                                                                opt.favorite ? <StarOutlinedIcon fontSize='large' className='text-warning' /> : <StarBorderIcon fontSize='large' className='text-warning' />
                                                            }
                                                        </IconButton>
                                                        :
                                                        <div>
                                                            {
                                                                opt.favorite ? <StarOutlinedIcon title="Favourite" fontSize='large' className='text-warning' /> 
                                                                   : <StarBorderIcon title="Not Favourite" fontSize='large' className='text-warning' />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {isLoggedUser ?
                                                    <div className="d-flex align-items-center pt-1" style={{ fontWeight: 400 }}>
                                                        <AccessTimeOutlinedIcon className="mr-1" />
                                                        <div className="heading-text">
                                                            Wating to receive this reward
                                                        </div>
                                                    </div>
                                                    :

                                                    <div className="d-flex align-items-center justify-content-between pt-2" style={{ fontWeight: 400 }}>
                                                        <div className="d-flex align-items-center">
                                                            <AccessTimeOutlinedIcon className="mr-1" />
                                                            <h2 className="mb-0 heading-text">{this.getDateDiffence(op.timeStamp)}</h2>
                                                        </div>
                                                        <Button title="Provide Reward" color="primary" variant="contained" size="small" style={{ borderRadius: 16 + "px", width: 150, paddingTop: 6 }} onClick={() => this.props.confirmRewards(op.shoppingId, selectedMemberId)}>
                                                            PROVIDE REWARD
                                                        </Button>
                                                    </div>
                                                }
                                            </li>))))
                                        :
                                        <div className="heading-text my-3 text-center">
                                            Waiting Rewards Not Found
                                        </div>
                                    }
                                </ul>
                            </div> :
                            selRewardOpt === rewardsOption[2] ?
                                <div style={{ height: 'auto' }}>
                                    <ul className="list-unstyled mt-3 mx-2" >
                                        {recivedReward && recivedReward.length > 0 ? recivedReward.map((opt, index) => (
                                            <li key={index} className="list-card mb-1  list-item border-bottom p-3" style={{ fontWeight: 500 }} data-testid={"received-reward-list-item-"+index}>
                                                {opt.url && opt.url.includes("http") &&
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <img className="img-fluid-ext border" width="auto" src={opt.url} alt="" style={{ borderRadius: 15 + "px", maxHeight: 250 }} data-testid="reward-image-item"/>
                                                    </div>
                                                }
                                                <div className="d-flex align-items-center justify-content-between my-2 border-bottom pb-2">
                                                    <h3 className="heading-text mb-0" style={{ fontWeight: 550 }}>{opt.title}</h3>
                                                    {isLoggedUser ?
                                                        <IconButton title={opt.favorite ? "Remove From Favourite" : "Add To Favourite"} onClick={() => this.props.addtoFavourite(opt.rewardId)} >
                                                            {
                                                                opt.favorite ? <StarOutlinedIcon fontSize='large' className='text-warning' /> : <StarBorderIcon fontSize='large' className='text-warning' />
                                                            }
                                                        </IconButton>
                                                        :
                                                        <div>
                                                            {
                                                                opt.favorite ? <StarOutlinedIcon title="Favourite" fontSize='large' className='text-warning' /> 
                                                                  : <StarBorderIcon title="Not Favourite" fontSize='large' className='text-warning' />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {isLoggedUser && opt.shopping && opt.shopping.length > 0 ?
                                                    <div className="d-flex align-items-center justify-content-between" onClick={() => this.handleOpenProvidedTimes(opt)} style={{ fontWeight: 400, cursor: "pointer", marginLeft: -2 }}>
                                                        <div className="d-flex align-items-center">
                                                            <AccessTimeOutlinedIcon fontSize="large" color="primary" className="mr-1" />
                                                            <h4 className="mb-0 heading-text">You received this reward {opt.shopping.length} times</h4>
                                                        </div>
                                                        <IconButton title={"Open Provided Times"} >
                                                            <ArrowForwardIosIcon color="primary" />
                                                        </IconButton>
                                                    </div>
                                                    :
                                                    <div className="d-flex align-items-center justify-content-between" onClick={() => this.handleOpenProvidedTimes(opt)} style={{ fontWeight: 400, cursor: "pointer", marginLeft: -2 }}>
                                                        <div className="d-flex align-items-center">
                                                            <AccessTimeOutlinedIcon fontSize="large" color="primary" className="mr-1" />
                                                            <h4 className="mb-0 heading-text">This reward was provided {opt.shopping.length} times</h4>
                                                        </div>
                                                        <IconButton title={"Open Provided Times"} >
                                                            <ArrowForwardIosIcon color="primary" />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </li>))
                                            :
                                            <div className="heading-text my-3 text-center">
                                                Received Rewards Not Found
                                            </div>
                                        }
                                    </ul>
                                </div> :
                                <div style={{ minHeight: 460 + "px" }}>
                                    <ul  data-testid="landing-page-my-rewards-List" className="list-unstyled mt-3 mx-2">
                                        {rewardsOption.map((opt, index) => (
                                            <li  data-testid={`landing-page-my-rewards-List-item-${index}`} key={index} className="d-flex list-card mb-2 justify-content-between align-items-center list-item" onClick={() => this.handleReward(opt)} style={{ fontWeight: 500, cursor: 'pointer' }}>
                                                <Button className="p-3 d-flex align-items-center justify-content-start w-100">
                                                    {index === 0 ? <LocalGroceryStoreOutlinedIcon color="primary" style={{ fontSize: 28 + "px" }} />
                                                        : index === 1 ?
                                                            <Badge className="" badgeContent={totalCount} color="error">
                                                                <AccessTimeOutlinedIcon color="primary" style={{ fontSize: 28 + "px" }} />
                                                            </Badge>
                                                            : <CardGiftcardOutlinedIcon color="primary" style={{ fontSize: 28 + "px" }} />
                                                    }
                                                    <h3 data-testid={`landing-page-my-rewards-lits-item-title-${index}`} className="heading-text ml-3 mb-0 text-left">{opt}</h3>
                                                </Button>
                                            </li>))
                                        }
                                    </ul>
                                </div>}

                </CustomScrollbars>

                {this.state.showProvidedTimes &&
                    <RewardsProvidedTimes
                        popupThemeClass={this.props.popupThemeClass}
                        selectedReward={this.state.selectedReward}
                        showProvidedTimes={this.state.showProvidedTimes}
                        handleCloseProvidedTimes={this.handleCloseProvidedTimes}
                    />
                }
                {/* <Footer email={email} userId={uid} /> */}
            </div>);
    }
}

const mapStateToProps = ({ authUser, Reward, settings, clientsList }) => {
    const { usersInfo, credit } = authUser;
    const { clientUserDetail } = clientsList
    const { recivedReward, pendingReward, buyReward, loading } = Reward;
    const { popupThemeClass } = settings;
    const {profileImageURL: authUserImage, displayName: authUserName = "Client"} = usersInfo || {}
    const {profileImageURL: clientImage, displayName: clientName = "Client", credit: userCredit} = clientUserDetail || {}
     // clientUserDetail.profileImageURL : require('Assets/avatars/user-15.jpg');
    return { recivedReward, pendingReward, buyReward, loading, credit, userCredit, authUserImage, authUserName, clientImage, clientName, popupThemeClass };
}

export default connect(mapStateToProps, { getRewards, buyRewardByRewardId, getPendingRewards, addtoFavourite, getreceivedRewards, confirmRewards })(RewardsPage);