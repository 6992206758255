import React, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

const RegisterDevice = ({registerDeviceForNotification}) => {
  const [voipId, setVoipId] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);

  const setDeviceFromLocalStorage = () => {
    const voipId = localStorage.getItem("voipToken")
    const fcmToken = localStorage.getItem("fcmToken")
    if (voipId) {
        setVoipId(voipId)
    }
    if (fcmToken) {
        setFcmToken(fcmToken)
    }
  }

  // Attach event listeners to receive tokens from different parts of your code
  useEffect(() => {

    //setDevices if already available
    setDeviceFromLocalStorage()
    // Event handler for receiving voipId
    const handleVoipReceived = (event) => {
      const { voipToken } = event.detail || {};
      if (voipToken) {
        console.log("Received voipId:", voipToken);
        setVoipId(voipToken);
      }
    };

    // Event handler for receiving fcmToken
    const handleFcmReceived = (event) => {
      const { fcmToken } = event.detail || {};
      if (fcmToken) {
        console.log("Received fcmToken:", fcmToken);
        setFcmToken(fcmToken);
        setIsRegistered(false)
      }
    };

    // Event handler for receiving fcmToken
    const handlelogout = (event) => {
        console.log("Received logout:");
        setIsRegistered(false)
    };

    // Attach event listeners
    window.addEventListener('voipReceived', handleVoipReceived);
    window.addEventListener('fcmReceived', handleFcmReceived);
    window.addEventListener('logout', handlelogout);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener('voipReceived', handleVoipReceived);
      window.removeEventListener('fcmReceived', handleFcmReceived);
      window.removeEventListener('logout', handlelogout);
    };
  }, []);

  // When both voipId and fcmToken are available, call the registration API
  useEffect(() => {
    const registerDevice = async () => {
        if (isRegistered) {
            return
        }
        if ((Capacitor.getPlatform() == "ios" && voipId && fcmToken) || ((Capacitor.getPlatform() == "android") || (Capacitor.getPlatform() == "web") && fcmToken)) {
            try {
                await registerDeviceForNotification(fcmToken, voipId)
                console.log('Registration successful:');
                setIsRegistered(true);
            } catch (error) {
                console.error('Registration failed:', error);
            }
        } 
    };

    registerDevice();
  }, [voipId, fcmToken, isRegistered]);

  // This component doesn't render any visible UI
  return null;
};

export default RegisterDevice;
